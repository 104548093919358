<template>
  <div class="advertising-main">
      <a class="advertising-main-first advertising-main-item" target="_blank" href="http://www.cscb.cn/"></a>
      <a class="advertising-main-two advertising-main-item" target="_blank" href="https://www.hnsggzy.com/"></a>
      <a class="advertising-main-second advertising-main-item" target="_blank" href="http://www.hnxzzx.cn"></a>
      <a class="advertising-main-third advertising-main-item" target="_blank" href="http://csggzy.changsha.gov.cn/"></a>
  </div>
</template>

<script>
export default {
  name: "advertising"
}
</script>

<style scoped>
.advertising-main{
  width: 100%;
}
.advertising-main-first{
  background-image: url("../../../assets/image/pc/icon_gyljr.jpg");
}
.advertising-main-item{
  display: flex;
  width: 100%;
  height: 110px;
  margin-top: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.advertising-main-two{
  background-image: url("../../../assets/image/pc/ggzy.jpg");
}.advertising-main-third{
  background-image: url("../../../assets/image/pc/changshaggzyjyzx.jpg");
   margin-bottom: 20px;
 }
.advertising-main-second{
  background-image: url("../../../assets/image/pc/icon_zbdl.jpg");
}
</style>
