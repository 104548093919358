<template>
  <div class="notice" ref="cms-content">
    <cms-header ref="headerHeight"></cms-header>
    <!--面包屑-->
    <div class="width_width child_bg_1">
      <div class="width_w clearfix">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>

          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link to="/purchase">
            <a class="now_a left" href="#">招采公告</a>
          </router-link>
        </div>
      </div>
      <span class="child_name">招采公告</span>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left">
        <ul class="tab_con clearfix">
          <li v-for="(item,index) in noticeTypeList" :key="'notice_'+index">
            <a :class="activeClass == index ? 'tab_active':''" @click="getItem(index,item,'activeClass')"
               class="tab_li"
               href="javascript:void(0)">{{ item.categoryTitle }}</a>
          </li>
        </ul>
        <div class="n_nar">
          <dl class="n_dl">
            <dt>公告搜索：</dt>
            <dd>
              <b-form-input id="menu_search" @keyup="check_menu_search" style="width:260px" size="lg"
                            placeholder="请输入公告/公司名称关键字"></b-form-input>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>业务类型：</dt>
            <dd>
              <a :class="activeClass1 == index ? 'dd_select':''" @click="getItem(index,item,'activeClass1')"
                 :key="'activeClass-1-'+index"
                 href="javascript:void(0)"
                 v-for="(item,index) in businessType">{{ item.typeName }}</a>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>公告类型：</dt>
            <dd>
              <a :class="activeClass4 == index ? 'dd_select':''" @click="getItem(index,item,'activeClass4')"
                 :key="'activeClass-4-'+index"
                 href="javascript:void(0)"
                 v-for="(item,index) in purchaseMode">{{ item.typeName }}</a>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>招标类型：</dt>
            <dd>
              <a :class="activeClass3 == index ? 'dd_select':''" @click="getItem(index,item,'activeClass3')"
                 :key="'activeClass-3-'+index"
                 href="javascript:void(0)" v-for="(item,index) in formType">{{ item.typeName }}</a>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>发布时间：</dt>
            <dd>
              <a :class="activeClass2 == index ? 'dd_select':''" @click="getItem(index,item,'activeClass2')"
                 :key="'activeClass-2-'+index"
                 href="javascript:void(0)"
                 v-for="(item,index) in releaseTime">{{ item.scopeName }}</a>
            </dd>
          </dl>
        </div>
        <div class="tender_con">
          <el-empty  v-if="total ==0">
          </el-empty>
          <router-link v-for="(item,index) in noticeList" class="tender_a line_a clearfix" :key="'details-'+index"
                       :to="{'path': '/purchaseDetails', 'query': {'id': item.id,'contentId': item.contentId, 'type': item.type,'formType':item.formType,'packageId':item.packageId,'vc':item.vc}}">
            <span class="left tender_span">
                    <i v-if="item.purchaseMode=='186'">【议价】</i>
                    <i v-else-if="item.purchaseMode==10 || item.purchaseMode== 210 ">【招标】</i>
                    <i v-else-if="item.purchaseMode=='201'">【竞争性谈判】</i>
                    <i v-else-if="item.purchaseMode=='202'">【竞争性磋商】</i>
                    <i v-else-if="item.purchaseMode=='204'">【询比价】</i>
                    <i v-else-if="item.purchaseMode=='203'">【比选采购】</i>
                    <i v-else-if="item.purchaseMode=='205'">【单一来源】</i>
                    <i v-else-if="item.purchaseMode=='206'">【竞价】</i>
                    <i v-else-if="item.purchaseMode=='207'">【供应商入围】</i>
                    <i v-else-if="item.purchaseMode=='30'">【单一来源】</i>
                    <i v-else-if="item.purchaseMode=='40'">【询价】</i>
                    <i v-else-if="item.purchaseMode=='50'">【竞价】</i>
                    <i v-else-if="item.purchaseMode=='60'">【询比价】</i>
                    <i v-else-if="item.purchaseMode=='70'">【竞争性谈判 | 磋商】</i>
                    <i v-else-if="item.purchaseMode=='80'">【单一来源】</i>
                    <i v-else-if="item.purchaseMode=='90'">【竞争性磋商】</i>
                    <i v-else-if="item.purchaseMode==null">【流标】</i>
                    {{ item.title }}</span>
            <span class="left tender_company">{{ item.companyName }}</span>
            <span class="right tender_time">{{ formatDate(item.createTime, 'YYYY/MM/DD') }}</span>
          </router-link>
        </div>
        <div class="cms-box" style="margin-top: 10px"  v-if="total > 0">
          <el-pagination
              :page-size="pageSize"
              :total="total"
              :pager-count="5"
              @current-change="handleCurrentChange"
              background
              :current-page="currentPage"
              layout="prev, pager, next">
          </el-pagination>
        </div>
      </div>
      <div class="notice-right right cms-sm-hidden cms-ssm-hidden cms-md-hidden">
        <div class="child_tool">
          <!--工具栏-->
          <toolbar></toolbar>
          <!--广告-->
          <advertising></advertising>
          <!--联系卡片-->
          <contact-card></contact-card>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import Toolbar from "../../components/commoon/component/toolbar";
import ContactCard from "../../components/commoon/component/contact-card";
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";
import Advertising from "../../components/commoon/component/advertising";
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "purchase",
  components: {Advertising, CmsFoot, CmsHeader, Toolbar, ContactCard},
  data() {
    return {
      buyer: {},
      buyerList: [],
      noticeTypeList: [],
      noticeList: [],
      activeClass: 0,
      activeClass1: 0,
      activeClass2: 0,
      activeClass3: 0,
      activeClass4: 0,
      configForm: {},
      businessType: [
        {
          typeName: "全部",
          code: "",
        },
        {
          typeName: "货物",
          code: "10",
        },
        {
          typeName: "工程",
          code: "20",
        },
        {
          typeName: "服务",
          code: "30",
        }
      ],
      formType: [
        {
          typeName: "全部",
          code: "",
          type: "all"
        },
        {
          typeName: "公开",
          code: "10",
          type: "BID_BULLETIN"
        },
        {
          typeName: "邀请",
          code: "20",
          type: "BGG_INVITATION"
        },
      ],
      purchaseMode: [
        {
          typeName: "全部",
          code: "0",
        },
        {
          typeName: "招标",
          code: "10",
        },
        {
          typeName: "非招标",
          code: "-10",
        },
      ],
      releaseTime: [
        {
          scopeName: "全部",
          code: ""
        }, {
          scopeName: "今天",
          code: "10"
        }, {
          scopeName: "近三天",
          code: "20"
        }, {
          scopeName: "近五天",
          code: "30"
        }, {
          scopeName: "近一周",
          code: "40"
        }, {
          scopeName: "近半个月",
          code: "50"
        }, {
          scopeName: "本月",
          code: "60"
        },
      ],
      query: {},
      total: 0,
      currentPage: 0,
      pageSize: 16,
      userInfo:{},
      userLoginFlag:false,
    }
  },
  methods: {
    login: function () {
      if (this.userLoginFlag) {
        this.end();
      } else {
        this.start();
      }
      window.open(this.member + "/member/login/executeLogin.htm");
    },
    start() {
      // 将定时器名字赋值到变量中
      this.timer = setInterval(() => {
        this.checkUser(this.getCookie("uuid"));
        console.log("开始");
        if (this.userLoginFlag){
          this.end()
        }
      }, 5000);
    },
    end() {
      this.checkUser(this.getCookie("uuid"));
      clearInterval(this.timer);
      this.timer = null // 这里最好清除一下，回归默认值
      // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
      console.log("结束！");
    },
    selectNoticeList: function (modelId, name, type) {
      const that = this;
      this.$http.get(this.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
        that.query.type = data.data.result[that.activeClass].noticeType;
        if (that.query.type == 'BID_BULLETIN') {
          that.query.type = 'all';
        }
        that.query.purchaseMode = data.data.result[that.activeClass].purchaseMode;
        if (type == 2) {
          that.query.purchaseMode = "-10";
        } else if (type == 1) {
          that.query.purchaseMode = "10";
        } else {
          that.query.purchaseMode = "";
        }
        let id = that.getParameter("id");
        if (id != null && id != '') {
          that.query.companyName = id;
        }
        that.checkUser(that.getCookie("uuid"));
        that.query.loginCompanyId = that.userInfo.companyId;
        that.getNoticeList(that.query);
      }).catch(function (error) {
        console.log(error);
      })
    },
    getNoticeList: function (query) {
      var that = this;
      this.$http.post(this.base + "/bulletin/getAll.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsBulletinDTO: query
      }).then(function (data) {
        that.noticeList = data.data.result.records;
        that.total = data.data.result.total;
      }).catch(function (error) {
        console.log(error);
      })
    },
    getItem: function (index, item, activeClassName) {
      const that = this;
      this.currentPage = 0
      this[activeClassName] = index;
      /*公告类型*/
      if (activeClassName === "activeClass") {
        that.query.type = item.noticeType;
        if (item.noticeType == 'BID_BULLETIN') {
          that.query.type = 'all';
        }
      }
      /*业务类型*/
      if (activeClassName === "activeClass1") {
        that.query.businessType = item.code;
      }
      /*招标类型*/
      if (activeClassName === "activeClass3") {
        that.query.formType = item.code;
        if (item.code == '' && (that.query.type == 'BGG_INVITATION' || that.query.type == 'BID_BULLETIN')) {
          that.query.type = 'all';
        }
      }
      /*发布时间*/
      if (activeClassName === "activeClass2") {
        that.query.releaseTime = item.code;
      }
      /*类型*/
      if (activeClassName === "activeClass4") {
        that.query.purchaseMode = item.code;
      }
      that.query.loginCompanyId = that.userInfo.companyId;
      this.getNoticeList(that.query);
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        that.$http.post(that.base + "/promotion/page.do", {
          page: {
            size: 6,
            current: 0
          },
          cmsPromotionDTO: {
            categoryId: categoryId
          }
        }).then(function (data) {
          var data1 = data.data.result.records;
          for (var i = 0; i < data1.length; i++) {
            if (data1[i].imagesUrl != null && data1[i].imagesUrl != "") {
              data1[i].imagesUrl = JSON.parse(data1[i].imagesUrl);
            }
          }
          that[entityListName] = data1;
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
    getConfigs: function (id) {
      var that = this;
      this.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        var data = res.data.result;
        if (data) {
          that.configForm = data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleCurrentChange: function (val) {
      this.currentPage = val;
      this.getNoticeList(this.query);
    },
    formatDate: function (de, fmt) {
      return moment(de).format(fmt);
    },
    //键盘输入查询
    check_menu_search: function () {
      this.menu_search();
    },
    menu_search: function () {
      let val = $("#menu_search").val();
      if(val != ""){
        const pattern=/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
        val = val.replace(pattern,"");
      }
      this.query.searchName = val;
      this.getNoticeList(this.query);
    },
    getParameter: function (paraName) {
      var url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
  }, created() {
    this.checkUser(this.getCookie("uuid"));
    /*初始化公告栏目*/
    this.selectNoticeList("notice_type", "noticeTypeList", this.activeClass4);
    /*初始化采购专区*/
    this.selectPromotionList("buyer", "buyer", "buyerList");
    document.title = this.title;
  },
  mounted() {
    console.log(this.$refs.headerHeight.offsetHeight)
  }
}
</script>
<style>
/*分页背景颜色*/
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
